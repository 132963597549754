import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import {
  CancelButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from 'src/domains/root/commons/dialog';

export default function EnableInvoiceDialog({
  open,
  submitting,
  companyName,
  zipCode,
  address,
  telNo,
  email,
  showSuccessMessage,
  handleClose,
  handleSubmit,
}: {
  open: boolean;
  submitting: boolean;
  companyName: string;
  zipCode: string;
  address: string;
  telNo: string;
  email: string;
  showSuccessMessage: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const intl = useIntl();

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={572}
      loggingId="EnableInvoiceDialog"
    >
      <DialogTitle onClose={handleClose}>
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.title',
        })}
      </DialogTitle>
      <DialogContent>
        {showSuccessMessage ? (
          <Alert severity="info">
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.content.successMessage',
            })}
          </Alert>
        ) : (
          <>
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.content.description',
            })}

            <Box py={1}>
              <Box sx={{ wordBreak: 'break-all', mt: 3 }}>
                <Box display="flex">
                  <Typography
                    sx={{ minWidth: 120, display: 'inline-block' }}
                    component="span"
                  >
                    {intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.content.companyName',
                    })}
                  </Typography>
                  <Typography fontWeight="bold" pr={1}>
                    :
                  </Typography>
                  <Typography fontWeight="bold">{companyName}</Typography>
                </Box>
                <Box display="flex">
                  <Typography
                    sx={{ minWidth: 120, display: 'inline-block' }}
                    component="span"
                  >
                    {intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.content.zipCode',
                    })}
                  </Typography>
                  <Typography fontWeight="bold" pr={1}>
                    :
                  </Typography>
                  <Typography fontWeight="bold">{zipCode}</Typography>
                </Box>
                <Box display="flex">
                  <Typography
                    sx={{ minWidth: 120, display: 'inline-block' }}
                    component="span"
                  >
                    {intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.content.address',
                    })}
                  </Typography>
                  <Typography fontWeight="bold" pr={1}>
                    :
                  </Typography>
                  <Typography fontWeight="bold">{address}</Typography>
                </Box>
                <Box display="flex">
                  <Typography
                    sx={{ minWidth: 120, display: 'inline-block' }}
                    component="span"
                  >
                    {intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.content.telNo',
                    })}
                  </Typography>
                  <Typography fontWeight="bold" pr={1}>
                    :
                  </Typography>
                  <Typography fontWeight="bold">{telNo}</Typography>
                </Box>
                <Box display="flex">
                  <Typography
                    sx={{ minWidth: 120, display: 'inline-block' }}
                    component="span"
                  >
                    {intl.formatMessage({
                      id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.content.email',
                    })}
                  </Typography>
                  <Typography fontWeight="bold" pr={1}>
                    :
                  </Typography>
                  <Typography fontWeight="bold">{email}</Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {showSuccessMessage ? (
          <CancelButton onClick={handleClose} />
        ) : (
          <NegativeButton onClick={handleClose}>
            {intl.formatMessage({ id: 'common.buttons.no' })}
          </NegativeButton>
        )}
        {!showSuccessMessage && (
          <PrimaryButton disabled={submitting} onClick={handleSubmit}>
            {!submitting ? (
              intl.formatMessage({
                id: 'pages.OtherSetting.contracts.payment-method.invoice.enableInvoiceDialog.buttons.enable',
              })
            ) : (
              <CircularProgress color="inherit" size={20} />
            )}
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
