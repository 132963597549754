import React from 'react';
import { useIntl } from 'react-intl';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'src/domains/root/commons/dialog';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';

interface Props {
  open: boolean;
  title: string;
  content: React.ReactNode;
  closeText?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | number;
  loggingId: string;
  close: () => void;
}

export default function InfoDialog(props: Props) {
  const {
    open,
    title,
    content,
    closeText,
    maxWidth = 'sm',
    loggingId,
    close,
  } = props;

  const intl = useIntl();

  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth} loggingId={loggingId}>
      <DialogTitle onClose={close}>{title}</DialogTitle>
      <DialogContent sx={{ py: 3 }}>{content}</DialogContent>
      <DialogActions sx={{ px: 3.5 }}>
        <NegativeButton onClick={close}>
          {closeText ?? intl.formatMessage({ id: 'common.cancel' })}
        </NegativeButton>
      </DialogActions>
    </Dialog>
  );
}
