import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Table,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  TableCell,
  TableBody as MuiTableBody,
  useTheme,
  useMediaQuery,
  Alert,
} from '@mui/material';
import { Text } from 'src/shared/ui';
import AccessControl from 'src/domains/root/commons/AccessControl';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import { useSelector } from 'src/domains/root/store';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import { useCheckFeatureFlag } from 'src/utils/feature-flag/FeatureFlag';
import * as API from 'src/apis';
import { IS_ENV } from 'src/constants';
import { activeWorkspaceSelector } from '../users/slice';
import ContractExpiredAt from './ContractExpiredAt';
import StripeCustomerUpdateDialog from './StripeCustomerUpdateDialog';
import ContractUpdateDialog from './ContractUpdateDialog';
import { PostPaymentsParams, createResultSelector } from './paymentSlice';
import ContractHistoryButton from './ContractHistoryButton';

export type Step = 'payment-method' | 'credit' | 'invoice';

interface ContractTableProps {
  gateways: Gateway[];
  defaultPaymentMethod: API.DefaultCabiotPaymentType;
  payments: API.Payment[];
  paymentsResult: ReturnType<typeof createResultSelector>;
  submitPayments: (params: PostPaymentsParams) => Promise<void>;
  resetPaymentsResult: () => void;
  paymentsInvoicesReview: API.GetWorkspacesGatewaysPaymentsInvoicesResponse;
  updateGatewaysPaymentsInvoceReview: (gateways: Gateway[]) => void;
}

export default function ContractTable(props: ContractTableProps) {
  const {
    gateways,
    defaultPaymentMethod,
    payments,
    paymentsResult,
    submitPayments,
    resetPaymentsResult,
    paymentsInvoicesReview,
    updateGatewaysPaymentsInvoceReview,
  } = props;

  const intl = useIntl();
  const isPaymentMethodFeatureEnabled = useCheckFeatureFlag(
    'contract.payment-method',
  );

  const [isContractupdatedialogOpen, setIsContractUpdateDialogOpen] =
    useState(false);
  const [gatewayForPayment, setGatewayForPayment] = useState<Gateway | null>(
    null,
  );
  const [contractUpdatedialogStep, setContractUpdatedialogStep] =
    useState<Step>('payment-method');

  const isInvoiceActivating = payments.some(
    (payment) => payment.type === 'invoice' && payment.status === 'activating',
  );

  const openCustomerUpdateDialog = (gateway: Gateway) => {
    setGatewayForPayment(gateway);
    setIsContractUpdateDialogOpen(true);
    setContractUpdatedialogStep('payment-method');

    resetPaymentsResult();
  };

  const closeContractUpdateDialog = () => {
    setIsContractUpdateDialogOpen(false);
  };

  const handleClickCreditCardPaymentButton = (gateway: Gateway) => {
    openCustomerUpdateDialog(gateway);
  };

  const handleStep = (step: Step) => {
    setContractUpdatedialogStep(step);
  };

  const handlePayment = async (params: PostPaymentsParams) => {
    await submitPayments(params);

    closeContractUpdateDialog();
  };

  useEffect(() => {
    if (
      paymentsResult.status === 'succeeded' ||
      paymentsResult.status === 'failed'
    ) {
      window.scrollTo(0, 0);
    }
  }, [isContractupdatedialogOpen, paymentsResult.status]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box
          sx={{
            py: { xs: 2.5, sm: 3 },
            px: { xs: 2, sm: 3.5 },
            backgroundColor: 'white',
            borderRadius: 1,
          }}
        >
          {!IS_ENV.DEMO && contractUpdatedialogStep === 'invoice' && (
            <InvoicePaymentsResultMessage
              gateway={gatewayForPayment}
              paymentsResult={paymentsResult}
            />
          )}
          {isInvoiceActivating && (
            <Box pb={1.5}>
              <Alert severity="info">
                {intl.formatMessage({
                  id: 'pages.OtherSetting.contracts.list.table.inoviceActivatingMessage',
                })}
              </Alert>
            </Box>
          )}
          <TableContainer>
            <Table aria-labelledby="tableTitle">
              <TableHead />
              <MuiTableBody>
                {gateways.map((gateway) => (
                  <TableBodyRow
                    key={gateway.deviceId}
                    gateway={gateway}
                    isInvoiceActivating={isInvoiceActivating}
                    invoicesReviewGateways={paymentsInvoicesReview}
                    handleClickButton={() =>
                      handleClickCreditCardPaymentButton(gateway)
                    }
                  />
                ))}
              </MuiTableBody>
            </Table>
          </TableContainer>
        </Box>
        <PaymentReaderOnly>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            bgcolor={{ xs: 'transparent', sm: 'white' }}
            pt={{ xs: 4, sm: 1 }}
            pb={4}
          >
            <ContractHistoryButton />
          </Box>
        </PaymentReaderOnly>
      </Box>
      {gatewayForPayment && !isPaymentMethodFeatureEnabled && (
        <StripeCustomerUpdateDialog
          open={!!gatewayForPayment}
          close={() => setGatewayForPayment(null)}
          gateway={gatewayForPayment}
        />
      )}
      {isContractupdatedialogOpen &&
        gatewayForPayment &&
        isPaymentMethodFeatureEnabled && (
          <ContractUpdateDialog
            open={isContractupdatedialogOpen}
            step={contractUpdatedialogStep}
            gateway={gatewayForPayment}
            defaultPaymentMethod={defaultPaymentMethod}
            payments={payments}
            submitting={paymentsResult.status === 'loading'}
            handleStep={handleStep}
            handleClose={closeContractUpdateDialog}
            handleSubmit={handlePayment}
            updateGatewaysPaymentsInvoceReview={
              updateGatewaysPaymentsInvoceReview
            }
          />
        )}
    </>
  );
}

// ///////////////////
// components

function InvoicePaymentsResultMessage({
  gateway,
  paymentsResult,
}: {
  gateway: Gateway | null;
  paymentsResult: ReturnType<typeof createResultSelector>;
}) {
  const intl = useIntl();

  const isSuccessful = paymentsResult.status === 'succeeded';
  const isFailed = paymentsResult.status === 'failed';

  return isSuccessful || isFailed ? (
    <Box pt={1} pb={2}>
      {isSuccessful && (
        <Alert>
          <Text>
            {intl.formatMessage(
              {
                id: 'pages.OtherSetting.contracts.list.payments.success.invoice',
              },
              { gatewayName: gateway?.name },
            )}
          </Text>
        </Alert>
      )}
      {isFailed && (
        <Alert severity="error">
          {intl.formatMessage({
            id: `pages.OtherSetting.contracts.list.payments.error.${paymentsResult.error?.code}`,
          })}
        </Alert>
      )}
    </Box>
  ) : null;
}

function TableHead() {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();
  const isPaymentMethodFeatureEnabled = useCheckFeatureFlag(
    'contract.payment-method',
  );
  const activeWorkspace = useSelector(activeWorkspaceSelector);
  const isAdmin = activeWorkspace?.role === 'admin';

  return (
    <MuiTableHead>
      <TableRow sx={{ borderBottom: '2px solid #828282' }}>
        <TableHeadCell>
          {intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.table.headCell.gatewayName',
          })}
        </TableHeadCell>
        {!isMediumScreen && (
          <TableHeadCell>
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.list.table.headCell.imei',
            })}
          </TableHeadCell>
        )}
        <TableHeadCell>
          {intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.table.headCell.expiredAt',
          })}
        </TableHeadCell>
        {isAdmin && (
          <TableHeadCell>
            {isPaymentMethodFeatureEnabled &&
              intl.formatMessage({
                id: 'pages.OtherSetting.contracts.list.table.headCell.update',
              })}
          </TableHeadCell>
        )}
      </TableRow>
    </MuiTableHead>
  );
}

function TableHeadCell({ children }: { children?: React.ReactNode }) {
  return (
    <TableCell
      align="center"
      sx={(theme) => ({
        paddingX: 1,
        paddingY: { xs: 1.5, sm: 2 },
        fontWeight: 700,
        fontSize: {
          xs: theme.typography.pxToRem(13),
          sm: theme.typography.pxToRem(15),
        },
      })}
    >
      {children}
    </TableCell>
  );
}

function TableBodyRow({
  gateway,
  isInvoiceActivating,
  invoicesReviewGateways,
  handleClickButton,
}: {
  gateway: Gateway;
  isInvoiceActivating: boolean;
  invoicesReviewGateways: API.GetWorkspacesGatewaysPaymentsInvoicesResponse;
  handleClickButton: () => void;
}) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();
  const isPaymentMethodFeatureEnabled = useCheckFeatureFlag(
    'contract.payment-method',
  );

  const isInvoiceReviewStatus = invoicesReviewGateways.gatewayIds.includes(
    gateway.deviceId,
  );
  const isContractButtonDisabled = isInvoiceActivating || isInvoiceReviewStatus;

  const highlight =
    gateway.numOfRemainingDays != null &&
    gateway.numOfRemainingDays >= 0 &&
    gateway.numOfRemainingDays <= 31;

  return (
    <TableRow
      key={gateway.deviceId}
      sx={{
        backgroundColor: highlight ? '#FFF4E5' : undefined,
        borderSpacing: '10px',
      }}
    >
      <TableBodyCell align="left">
        <Box
          minWidth={172}
          sx={{
            wordBreak: 'break-all',
            fontWeight: highlight ? 'bold' : undefined,
          }}
        >
          {gateway.name}
        </Box>
      </TableBodyCell>
      {!isMediumScreen && (
        <TableBodyCell align="center">
          <Box
            minWidth={160}
            sx={{
              fontWeight: highlight ? 'bold' : undefined,
            }}
          >
            {gateway.physicalId}
          </Box>
        </TableBodyCell>
      )}
      <TableBodyCell align="center">
        <Box
          minWidth={{ xs: 80, sm: 112 }}
          sx={{
            color: highlight ? theme.palette.nito.main : undefined,
            fontWeight: highlight ? 'bold' : undefined,
          }}
        >
          <ContractExpiredAt
            expiredAt={gateway.contractExpiredAt}
            formatType="slash"
          />
          <br />
          {highlight && (
            <>
              {intl.formatMessage(
                {
                  id: 'pages.OtherSetting.gateways.numOfRemainingDays',
                },
                {
                  numOfRemainingDays: gateway.numOfRemainingDays,
                },
              )}
            </>
          )}
        </Box>
      </TableBodyCell>
      <ContractUpdaterOnly>
        <TableCell align="center" sx={{ px: { xs: 1, sm: 2 } }}>
          {isPaymentMethodFeatureEnabled ? (
            <SecondaryButton
              disabled={isContractButtonDisabled}
              onClick={handleClickButton}
              sx={{ width: 104 }}
            >
              {isInvoiceReviewStatus
                ? intl.formatMessage({
                    id: 'pages.OtherSetting.contracts.list.table.bodyCell.updatingContract',
                  })
                : intl.formatMessage({
                    id: 'pages.OtherSetting.contracts.list.table.bodyCell.updateContract',
                  })}
            </SecondaryButton>
          ) : (
            <SecondaryButton
              onClick={() => {
                handleClickButton();
              }}
              sx={{ minWidth: 212 }}
            >
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.list.table.bodyCell.creditCardPaymentButton',
              })}
            </SecondaryButton>
          )}
        </TableCell>
      </ContractUpdaterOnly>
    </TableRow>
  );
}

function TableBodyCell({
  align,
  children,
}: {
  align: 'left' | 'center';
  children: React.ReactNode;
}) {
  return (
    <TableCell
      align={align}
      sx={(theme) => ({
        paddingX: 1,
        paddingY: 2,
        fontSize: {
          xs: theme.typography.pxToRem(13),
          sm: theme.typography.pxToRem(15),
        },
      })}
    >
      {children}
    </TableCell>
  );
}

function ContractUpdaterOnly({ children }: { children: React.ReactNode }) {
  const activeWorkspace = useSelector(activeWorkspaceSelector);

  return (
    <AccessControl
      permit={(permissions) =>
        permissions.includes('cabiotpack.contract.update') &&
        permissions.includes('stripe.customer.name.update')
      }
    >
      {activeWorkspace && children}
    </AccessControl>
  );
}

function PaymentReaderOnly({ children }: { children: React.ReactNode }) {
  const activeWorkspace = useSelector(activeWorkspaceSelector);

  return (
    <AccessControl
      permit={(permissions) => permissions.includes('cabiotpack.payment.list')}
    >
      {activeWorkspace && children}
    </AccessControl>
  );
}
